* {
  padding: 0;
  margin: 0;
  font-family: 'open-sans', sans-serif;
}

body {
  overflow-x: hidden;
}

.wrapper {
  margin-right: auto; /* 1 */
  margin-left:  auto; /* 1 */

  max-width: 1060px; /* 2 */

  padding-right: 10px; /* 3 */
  padding-left:  10px; /* 3 */
}