.service-cont {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.service-cont > div {
    padding: 20px;
    color: white;
}

.service-text {
    padding: 0 0 20px 0
}

