.hero{
    padding: 200px 0;
    color: white;
    text-align: center;
}

p > span {
    font-weight: 700;
}

/* .wrapper {
    text-align: center;
} */

.services-title {
    padding: 50px;
}

.services-section {
    background: #EB871C;
    color: white;
    text-align: center;
    padding: 30px;
    padding-bottom: 100px;
}

.services-cont {
    display: flex;
    justify-content: space-between;
}

.service-icon-link {
    text-decoration: none;
}

.service-card {
    text-align: center;
    padding: 30px;
    border-radius: 15px;
    transition: 0.3s;
}

.service-card>h3 {
    padding: 30px;
    font-size: 20px;
    color: white;
    text-decoration: none;
}

.service-card:hover {
    background: white;
    color: #EB871C;
}

.service-card:hover h3{
    color: #EB871C;
}

.service-card:hover>.service-icon {
    fill: #EB871C;
    color: #EB871C;
}

.about-section {
    /* text-align: left; */
    color: white;
    padding: 50px;
    /* overflow-x: hidden; */
    background: linear-gradient(
        to bottom,
        rgba(0,0,0,0.65),
        black 60%,
        white 70%,
        white 
    ), url(../imgs/repair-dude.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.about-write-up {
    text-align: left;
    padding-bottom: 30px;;
}

.about-title {
    padding: 20px 0;
}

.client-title {
    padding: 20px 0;
}

.portfolio-section {
    text-align: right;
}

.crslqmark {
    background: white;
}

.crsl-div > img {
    display: block;
    max-width:100%;
    max-height:100%;
    margin: 0 auto;
}

.carousel-btn {
    background-color: rgba(76, 175, 79, 0);
    border: solid #EB871C 3px;
    border-radius: 15px;
    color: black;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 900;
}

.contact-section{
    background: #1C3F63;
    color: white;
    text-align: center;
    padding: 50px;
}

.contact-section>.wrapper>h2{
    padding-bottom: 50px;
}

.contact-btn {
    background-color: rgba(76, 175, 79, 0);
    border: solid white 3px;
    border-radius: 15px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 900;
    transition: 0.3s;
}

.contact-btn > a {
    color: white;
    text-decoration: none;
}

.contact-btn:hover {
    border: solid #EB871C 3px;
    background: white;
}

.contact-btn:hover > a {
    color: rgb(255, 132, 0);
}

.service-icon {
    fill: white;
}