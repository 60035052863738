.logo {
    max-width: 450px;
}

.header {
    background: white;
}

.desktop-nav {
    text-align: right;
}

.desktop-nav > p {
    padding: 15px 20px;
    font-size: 14px;
}

.nav-links {
    /* width: 50%; */
    display: flex;
    align-items: center;
    list-style: none;
}

.mobile-services {
    width: 100%;
}

.mobile-service-links {
    width: 100%;
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
}

.mobile-service-links > li {
    padding: 20px;
}

.services-toggle {
    color: black;
    border: none;
    background: white;
    text-decoration: none;
    padding: 10px 20px;
    padding-top: 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
}

.links {
    color: black;
    text-decoration: none;
}

.mobile-links {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

li {
    padding: 0 20px;
    padding-top: 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
}

.services-dd {
    display: none;
    position: absolute;
    transform: translate(20px);
    
    padding-right: 20px;
    padding-bottom: 20px;
}

.divider {
    height:17px;
}

.services-dd-cont:hover>.services-dd {
    display: block;
}

.dropdown-links {
    background: #2C303A;
    padding: 0 30px 0 0;
    text-align: left;
}

.dropdown-links>li {
    list-style: none;
    color: white;
    padding: 10px 20px;
    font-weight: 400;
}

.dropdown-links>li>a {
    list-style: none;
    color: white;
    text-decoration: none;
}

.dropdown-links>li:hover {
    list-style: none;
    color: white;
    background: black;
    padding: 10px 20px;
    font-weight: 500;
}

.mobile-nav {
    display: none;
    flex-direction: column;
}

@media only screen and (max-width: 1021px) {
    .mobile-nav {
        display: flex;
        justify-content: center;
    }
}
.mobile-menu-items > .nav-links{
    flex-direction: column;
}

.mobile-menu-items > .nav-links > li{
    padding: 20px;
}
.mobile-menu-items > .nav-links > li> a{
    padding: 20px;
}

.mobile-menu-items > .nav-links > .links{
    padding: 20px;
}
.logoLink {
    display: flex;
    justify-content: center;
}

.mobile-menu-button {
    background: none;
    border: none;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
}

.mobile-services {
    display: flex;
    justify-content: center;
    flex-direction: column;
}