.app {
  
  overflow-x: hidden;
  background: linear-gradient(
    rgba(0, 0, 0, 0.65), 
    rgba(0, 0, 0, 0.65)
  ), url(imgs/cooler.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.header > .wrapper {
  display: flex;
  justify-content: space-between;
}

.service-icon {
  fill: white;
  height: 256px;
}

@media only screen and (max-width: 1021px) {
  .desktop-nav {
    display: none;
  }
  .header > .wrapper {
    justify-content: center;
    flex-direction: column;
  }
  .service-card>h3 {
    padding: 10px;
    font-size: 14px;
  }
  .mobile-nav {
    display: flex;
    justify-content: center;
  }
  .logo {
    /* width: 100%; */
    display: block;
    width: 100%;
    height: auto;
  }
  .service-icon {
    fill: white;
    width: 65%;
    height: 150px;
  }
  .service-cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px;
  }

  .service-cont > div {
      padding: 20px;
      color: white;
      align-items: center;
  }
  .service-cont > div > img{
    width: 100%;
  }
}

@media only screen and (max-width: 740px) {
  .services-cont {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .desktop-nav {
    display: none;
  }
  .header > .wrapper {
    justify-content: center;
  }
  .logo {
    width: 100%;
  }
  .service-icon {
    fill: white;
    height: 100px;
  }
}